import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import company_Formation_og_img from "../assets/og-img/company-formation.png";
import Meta from "./Meta";

export default function Proprietorship() {
  return (
    <>
      <Meta
        title="Proprietorship Business Trade License in Bangladesh | Business Registration"
        description="A person is a proprietor, Proprietorship business trade license requried in Bangladesh. A individul can make a business licenses in Bangladesh, This separate legal entity"
        image={company_Formation_og_img}
        keywords="Proprietorship, Proprietorship business, business licenses for proprietor, Company Registration Bangladesh, private limited company"
      />
      {/* page landing title */}
      <div className="container-xxl" style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Proprietorship Business in Bangladesh
          </h1>
        </div>
      </div>
      {/* header img */}
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="Working in Dubai banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>
      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <p style={{ lineHeight: "28px" }}>
            This guide provides information on registration of a sole
            proprietorship in Bangladesh. To learn about various types of
            business entities in Bangladesh, refer to start a business in
            Bangladesh.
          </p>
          <p style={{ lineHeight: "28px" }}>
            Sole proprietorship is the simplest form of business structure in
            Bangladesh. However, it is suitable only for very small single-owner
            type business that does not carry any risks. Unlike a private
            limited liability company, a sole proprietorship does not provide
            limited liability protection and your personal assets are not
            protected from business risks.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Sole-Proprietorship – Key Facts</h3>
          <ul>
            <li>
              A sole proprietorship in Bangladesh does not constitute a separate
              legal entity therefore it is not distinct from the owner/
              proprietor. The business owner is personally accountable for all
              liabilities incurred during the course of the business.
            </li>
            <li>
              Any Bangladeshi citizen of at least 18 years of age is eligible
              for a sole proprietorship.
            </li>
            <li>
              A local commercial address must be provided as the business
              address for the sole proprietorship.
            </li>
            <li>
              Profits of the sole proprietorship are treated as income of the
              individual who owns the entity, thus it is subjected to a tax rate
              as that of personal income.
            </li>
            <li>
              As a Bangladesh sole proprietorship is not a legal entity, it
              cannot register another business firm.
            </li>
            <li>
              Sole proprietorships do not need to audit their accounts as any
              profits will be taxed as personal taxes.
            </li>
            <li>Trade License is the basic requirement for proprietorship.</li>
            <li>Trade License needs to be renewed annually.</li>
          </ul>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Documents Required and Registration Procedure</h3>
          <p>
            In order to register a sole proprietorship business in Bangladesh,
            the following documents/information are required:
          </p>
          <ul>
            <li>Proposed business name in Bangla</li>
            <li>Description of principal activities</li>
            <li>
              Local business address for the proposed business in any commercial
              area.
            </li>
            <li>Lease/Rent Agreement or title deed for the office address.</li>
            <li>Copy of National ID for the owner.</li>
          </ul>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Sole Proprietorship Advantages</h3>
          <ul>
            <li>
              <b>Ease of setting up:</b> It is the easiest and least expensive
              business structure to set up.
            </li>
            <li>
              <b>Owner Control:</b> As a sole proprietor you are in complete
              control of all the business affairs including decision making.
            </li>
            <li>
              <b>No profit sharing: </b> You accrue all income generated by the
              business.
            </li>
            <li>
              <b>Ease of termination: </b> Terminating a sole proprietorship is
              easier, less time consuming and less expensive than other business
              entities.
            </li>
            <li>
              <b>Least compliance requirements: </b> You are free of the
              obligation of filing returns annually and only need to renew your
              membership every year.
            </li>
          </ul>
        </div>
        <div
          style={{
            width: "90%",
            margin: "40px auto",
            padding: "50px",
            borderTopLeftRadius: "70px",
            background: "#f2f2f2",
          }}
        >
          <div
            style={{
              width: "90%",
              lineHeight: "28px",
              margin: "30px 50px",
              color: "#505050",
            }}
          >
            <h3>Sole Proprietorship Disadvantages</h3>
            <ul>
              <li>
                <b>No separate legal entity:</b> You are inseparable from your
                business. This makes you financially and legally responsible for
                all debts and legal actions against the business.
              </li>
              <li>
                <b>Unlimited liability:</b> Creditors can sue you for business
                debts and may obtain a court order to claim your personal
                assets, including property.
              </li>
              <li>
                <b>No corporate tax benefits or incentives:</b> Taxes are
                calculated at your personal income tax rate, and you do not
                benefit from the special tax advantages available to private
                limited companies.
              </li>
              <li>
                <b>Limited capital:</b> Capital is restricted to your personal
                finances and the profits generated by the business, making
                expansion challenging and limited.
              </li>
              <li>
                <b>No perpetual succession:</b> The business is tied to your
                existence, meaning it ceases to exist upon your death. However,
                a successor may continue the business under the same name.
              </li>
              <li>
                <b>Low public perception:</b> This structure is less favored for
                serious businesses, as it is difficult to secure large loans or
                attract high-caliber employees and senior executives who prefer
                more advanced business structures like private limited
                companies.
              </li>
              <li>
                <b>Sale/transfer of all or part of the business:</b> The
                business can only be transferred through the sale of its assets.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
