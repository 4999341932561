import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FaUserClock } from "react-icons/fa";
import { GiDuration } from "react-icons/gi";
import { RiCalendarScheduleFill } from "react-icons/ri";
import { VscFeedback } from "react-icons/vsc";
import img_2 from "../../assets/images/class-2.jpg";
import BottomRadius from "../ui/BottomRadius";
import TopLeftRadiusSection from "../ui/TopLeftRadiusSection";
import JoinCommunity from "./JoinOurComunity";

export default function TrainingAbout() {
  const instructors = [
    {
      name: "John Doe",
      role: "Senior VAT Consultant",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRppsWFFb1GMG5B0zGQCoV-CHns1US-PhLwMw&s",
    },
    {
      name: "Jane Smith",
      role: "Taxation & Compliance Expert",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRppsWFFb1GMG5B0zGQCoV-CHns1US-PhLwMw&s",
    },
    {
      name: "Michael Lee",
      role: "VAT Policy Analyst",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRppsWFFb1GMG5B0zGQCoV-CHns1US-PhLwMw&s",
    },
  ];
  return (
    <>
      <div
        style={{
          backgroundColor: "#4caf50",
          color: "#fff",
          borderBottomRightRadius: "150px",
          padding: "120px 0",
          textAlign: "center",
        }}
      >
        <h2>About Us</h2>
      </div>
      <BottomRadius>
      <section className="about-company py-5">
      <Container>
        <Row className="align-items-center">
          {/* Left Side: Text Content */}
          <Col md={6}>
            <h2 className="fw-bold">About the JK Associates</h2>
            <p>
              Introducing <b>JK Associates</b>, a trusted consultancy firm dedicated to business solutions 
              and legal advisory services. Since our inception, we have empowered numerous businesses 
              with expert guidance in company formation, trademark registration, and compliance matters.
            </p>
            <p>
              Our mission is to simplify the complexities of business operations, providing seamless 
              support for entrepreneurs and enterprises. With a team of experienced professionals, 
              we ensure top-notch services tailored to your needs.
            </p>
            {/* <Button variant="warning" className="fw-bold px-4 py-2">READ MORE</Button> */}
          </Col>

          {/* Right Side: Image */}
          <Col md={6} className="text-center">
            <img 
              src={img_2} 
              alt="JK Associates Team" 
              className="img-fluid rounded shadow"
            />
          </Col>
        </Row>
      </Container>
    </section>
      </BottomRadius>
      <div className="resource-container">
        <h2 className="resource-section-title">Our Course Packages</h2>
        <hr />
        <Container className="py-5">
      <Row className="justify-content-center">
        {/* Time */}
        <Col md={3} sm={6} className="d-flex justify-content-center">
          <Card className="text-center shadow p-3">
            <div className="resource-icon-circle">
              <FaUserClock style={{ fontSize: "30px", color: "#4caf50" }} />
            </div>
            <h4 className="mt-3">Accounting Training</h4>
          </Card>
        </Col>

        {/* Duration */}
        <Col md={3} sm={6} className="d-flex justify-content-center">
          <Card className="text-center shadow p-3">
            <div className="resource-icon-circle">
              <GiDuration style={{ fontSize: "30px", color: "#4caf50" }} />
            </div>
            <h4 className="mt-3">Taxation Training</h4>
          </Card>
        </Col>

        {/* Day */}
        <Col md={3} sm={6} className="d-flex justify-content-center">
          <Card className="text-center shadow p-3">
            <div className="resource-icon-circle">
              <RiCalendarScheduleFill style={{ fontSize: "30px", color: "#4caf50" }} />
            </div>
            <h4 className="mt-3">Business Courses</h4>
          </Card>
        </Col>

        {/* Fee */}
        <Col md={3} sm={6} className="d-flex justify-content-center">
          <Card className="text-center shadow p-3">
            <div className="resource-icon-circle">
              <VscFeedback style={{ fontSize: "30px", color: "#4caf50" }} />
            </div>
            <h4 className="mt-3">Management Training</h4>
          </Card>
        </Col>
      </Row>
    </Container>
      </div>
      {/* <Certificates /> */}
      <section
        style={{
          background: "linear-gradient(90deg, #4caf50,rgb(56, 172, 60))",
          padding: "100px 20px",
          position: "relative",
          marginBottom: "100px", // Allow space for the overlap
        }}
      >
        {/* Header Section */}
        <div
          className="text-center text-white"
          style={{
            maxWidth: "1200px",
            margin: "auto",
          }}
        >
          <p
            className="mb-0 fw-semibold"
            style={{
              fontSize: "20px",
              lineHeight: "1.5",
            }}
          >
            From 2017, JK Associates is a highly regarded law and consultancy
            firm with over 550 clients and outstanding reviews, known for
            delivering exceptional legal services in Bangladesh and beyond.
          </p>
        </div>

        {/* Mission and Vision Section */}
        <div
          className="container"
          style={{
            position: "relative",
            marginTop: "50px", // Prevent overlap on mobile
          }}
        >
          <div className="row justify-content-center">
            {/* Mission Card */}
            <div className="col-12 col-md-5 mb-4 mb-md-0">
              <div
                className="p-4 rounded shadow-sm text-center"
                style={{
                  backgroundColor: "#fff9f0",
                  borderRadius: "12px",
                }}
              >
                <h3
                  className="fw-bold"
                  style={{
                    color: "#4caf50",
                    fontSize: "1.5rem",
                  }}
                >
                  Mission
                </h3>
                <p className="text-muted mt-3">
                  Advance the common good through quality assurance of higher
                  education as the leader in equitable, transformative, and
                  trusted accreditation in the service of students and member
                  institutions.
                </p>
              </div>
            </div>

            {/* Vision Card */}
            <div className="col-12 col-md-5">
              <div
                className="p-4 rounded shadow-sm text-center"
                style={{
                  backgroundColor: "#fff9f0",
                  borderRadius: "12px",
                }}
              >
                <h3
                  className="fw-bold"
                  style={{
                    color: "#4caf50",
                    fontSize: "1.5rem",
                  }}
                >
                  Vision
                </h3>
                <p className="text-muted mt-3">
                  HLC will be the champion of quality higher education by
                  working proactively in support of students, institutions, and
                  their communities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <TopLeftRadiusSection>
        <h3>Why Charterd Skills</h3>
        <p>
          Chartered Skills is a professional & educational e-learning & hiring
          platform that provides a wide range of skill-oriented courses helping
          individuals reach their professional goals. Our platform enables
          entrepreneurs to learn their industry know-how, helps job seekers
          match their skill sets with the ideal jobs & allows professionals to
          remain relevant in their industries through upskilling &
          microlearning. When you complete a course on Chartered Skills, you
          become a certified expert through our accreditations.
        </p>
        <p>
          Chartered Skills is a professional & educational e-learning & hiring
          platform that provides a wide range of skill-oriented courses helping
          individuals reach their professional goals. Our platform enables
          entrepreneurs to learn their industry know-how, helps job seekers
          match their skill sets with the ideal jobs & allows professionals to
          remain relevant in their industries through upskilling &
          microlearning. When you complete a course on Chartered Skills, you
          become a certified expert through our accreditations.
        </p>
      </TopLeftRadiusSection>
      <section className="py-5 bg-light">
        <div className="container text-center">
          <h2 style={{color:"#4caf50"}} className="mb-4  fw-bold">
            Meet Our Advisor
          </h2>
          <div className="row justify-content-center">
            {instructors.map((instructor, index) => (
              <div key={index} className="col-md-4 col-sm-6 mb-4">
                <div className="card border-0 shadow-lg overflow-hidden instructor-card">
                  <img
                    src={instructor.image}
                    alt={instructor.name}
                    className="card-img-top"
                  />
                  <div className="card-body text-center">
                    <h4 className="text-dark fw-bold">{instructor.name}</h4>
                    <p className="text-muted">{instructor.role}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <style>
          {`
        .instructor-card {
          border-radius: 15px;
          transition: transform 0.3s ease-in-out;
        }
        .instructor-card:hover {
          transform: translateY(-10px);
        }
        .instructor-card img {
          height: 300px;
          object-fit: cover;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
        }
      `}
        </style>
      </section>
      <JoinCommunity/>
    </>
  );
}
