import React from 'react'
import { IoHomeOutline } from 'react-icons/io5'
import { MdOutlineMenu } from 'react-icons/md'
import { Link } from 'react-router-dom'
import logoImg from "../assets/images/logo-120x120.png"
import SearchForm from './SearchForm'

export default function CoursesNavbar() {
  return (
    <>
      <header>
        <Link to="/" className="logo">
          <img src={logoImg} width="70px" height="70px" alt="logo" />
        </Link>
        <search className="searchform">
          <SearchForm/>
        </search>
        <input type="checkbox" id="menu-bar" />
        <label htmlFor="menu-bar">
          <MdOutlineMenu style={{ fontSize: "45px" }} />
        </label>
        <nav className="navbar-1">
          <ul>
            <li style={{ display: "flex", alignItems: "center" }}>
              <IoHomeOutline style={{ color: "crimson" }} />
              <Link to="/training/info" className="nav-link-1">
                Home
              </Link>
            </li>
            <li style={{ display: "flex", alignItems: "center" }}>
              <Link to="/training/about" className="nav-link-1">
                About
              </Link>
            </li>
            <li>
              <Link
                style={{ display: "flex", alignItems: "center", gap: "3px" }}
                to="/training/accounting-course"
                className="nav-link-1"
              >
                {/* <img src={bdIcon} width={17} alt="worldicon" /> */}
                Accounting Training 
                {/* <FaCaretDown /> */}
              </Link>
          </li>
            <li>
              <Link
                style={{ display: "flex", alignItems: "center", gap: "3px" }}
                to="/training/taxation"
                className="nav-link-1"
              >
                {/* <img src={bdIcon} width={17} alt="worldicon" /> */}
                Taxation Training
                {/* <FaCaretDown /> */}
              </Link>
          </li>
            <li>
              <Link
                style={{ display: "flex", alignItems: "center", gap: "3px" }}
                to="/training/business-courses"
                className="nav-link-1"
              >
                {/* <img src={bdIcon} width={17} alt="worldicon" /> */}
                Business Courses
                {/* <FaCaretDown /> */}
              </Link>
          </li>
            <li>
              <Link
                style={{ display: "flex", alignItems: "center", gap: "3px" }}
                to="/training/management-training"
                className="nav-link-1"
              >
                {/* <img src={bdIcon} width={17} alt="worldicon" /> */}
                Management Training
                {/* <FaCaretDown /> */}
              </Link>
          </li>
          </ul>
        </nav>
      </header>
    </>
  )
}
