import { Button, Container } from "react-bootstrap";

const JoinCommunity = () => {
  return (
    <div style={{ backgroundColor: "#ebf0f2", padding: "80px 0", textAlign: "center" }}>
      <Container>
        <h2 style={{ fontWeight: "bold",color:"#4caf50" }}>Join Our Community</h2>
        <p>Click the button below and join our Facebook group.</p>
        <Button 
          style={{
            backgroundColor: "#f35120",
            border: "none",
            padding: "12px 24px",
            borderRadius: "25px",
            fontWeight: "bold"
          }}
        >
          JOIN NOW
        </Button>
      </Container>
    </div>
  );
};

export default JoinCommunity;
