import React from 'react'

export default function TopLeftRadiusSection({children}) {
  return (
    <div
    style={{
      width: "90%",
      margin: "40px auto",
      padding: "50px",
      borderTopLeftRadius: "70px",
      background: "#d0e4eb",
    }}
  >
    <div
      style={{
        width: "90%",
        lineHeight: "28px",
        margin: "30px 50px",
        color: "#505050",
      }}
    >
      
     {children}
    </div>
  </div>
  )
}
