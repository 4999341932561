import React from 'react'

export default function BottomRadius({children}) {
  return (
    <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
         {children}
        </div>
      </div>
  )
}
