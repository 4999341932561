import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import company_Formation_og_img from "../assets/og-img/company-formation.png";
import Meta from "./Meta";

export default function Partnership() {
  return (
    <>
      <Meta
        title="Partnership Business Registration in Bangladesh | Partnership Agreement"
        description="A partnership firm can be registered by rjsc an application in Form No. 1. Along with the incorporation certificate, the registration fee bank slip and a true copy of the partnership agreement"
        image={company_Formation_og_img}
        keywords="partnership firm Formation, partnership firm Registration, partnership business Bangladesh, Partnership Agreement, Partnership Business Registration"
      />
      <div className="container-xxl" style={{ padding: "7% 7% 0 7%" }}>
  <div>
    <h1 style={{ marginBottom: "20px", color: "black" }} className="display-6">
      Partnership Business in Bangladesh
    </h1>
  </div>
</div>
{/* header img */}
<div style={{ marginTop: "40px" }}>
  <img
    src={homeOfficeImg}
    alt="Working in Dubai banner"
    width={"100%"}
    style={{ borderBottomRightRadius: "137px" }}
  />
</div>
<div
  style={{
    background: "#ececed",
    padding: "30px 0",
    marginBottom: "40px",
    width: "90%",
    float: "inline-end",
    borderBottomLeftRadius: "85px",
  }}
>
  <div style={{ width: "80%", margin: "auto" }}>
    <p style={{ lineHeight: "28px" }}>
      A partnership is a type of business structure where two or more partners come together to start a business. In Bangladesh, partnerships are governed by the Partnership Act, 1932. This guide provides information on the registration and key aspects of a partnership in Bangladesh.
    </p>
    <p style={{ lineHeight: "28px" }}>
      A partnership is defined as the relationship between persons who have agreed to share the profits of a business carried on by all or any of them acting for all. It is not mandatory to register a partnership, but it is advisable to do so to enjoy certain legal rights.
    </p>
  </div>
</div>
<div
  style={{
    width: "90%",
    margin: "40px auto",
    padding: "50px",
    borderTopLeftRadius: "70px",
  }}
>
  <div
    style={{
      width: "90%",
      lineHeight: "28px",
      margin: "30px 50px",
      color: "#505050",
    }}
  >
    <h3>Partnership – Key Facts</h3>
    <ul>
      <li>
        <b>Liability:</b> A partnership is considered a separate legal entity only if registered. All partners are jointly and severally liable for the partnership's liabilities.
      </li>
      <li>
        <b>Taxation:</b> Partnerships are not taxed at the entity level. Profits are treated as part of each partner’s personal income and taxed at personal income tax rates.
      </li>
      <li>
        <b>Members & Management:</b> A partnership requires a minimum of 2 partners and can have up to 20 partners. Partners can be natural persons or companies.
      </li>
      <li>
        <b>No Limited Liability Partnership (LLP):</b> The concept of LLP does not exist in Bangladesh.
      </li>
      <li>
        <b>Registration:</b> While not mandatory, registration is advisable to enforce legal rights against third parties or other partners.
      </li>
    </ul>
  </div>
</div>
<div
  style={{
    width: "90%",
    margin: "40px auto",
    padding: "50px",
    borderTopLeftRadius: "70px",
    background: "#d0e4eb",
  }}
>
  <div
    style={{
      width: "90%",
      lineHeight: "28px",
      margin: "30px 50px",
      color: "#505050",
    }}
  >
    <h3>Documents Required and Registration Procedure</h3>
    <p>
      To register a partnership in Bangladesh, the following documents and information are required:
    </p>
    <ul>
      <li>Proposed partnership name</li>
      <li>Partnership agreement (duly notarized)</li>
      <li>Form I</li>
      <li>Particulars of the partners</li>
      <li>Residential address of the partners</li>
      <li>Details of the registered address for the partnership</li>
      <li>Percentage of the share of profit of each partner</li>
    </ul>
    <h4>Registration Steps:</h4>
    <ol>
      <li>
        <b>Step 1 - Choosing the Partnership Name:</b> Select a unique name and apply for name clearance through the Registrar of Joint Stock Companies and Firms (RJSC).
      </li>
      <li>
        <b>Step 2 - Prepare a Partnership Deed:</b> The deed should include details like the name and address of the firm, nature of business, capital contribution, profit-sharing ratio, and rules for retirement, death, or admission of partners.
      </li>
      <li>
        <b>Step 3 - Register the Partnership Deed:</b> Submit the partnership deed and Form I to the RJSC. Once approved, the partnership will be registered, and a Certificate of Registration will be issued.
      </li>
    </ol>
  </div>
</div>
<div
  style={{
    width: "90%",
    margin: "40px auto",
    padding: "50px",
    borderTopLeftRadius: "70px",
  }}
>
  <div
    style={{
      width: "90%",
      lineHeight: "28px",
      margin: "30px 50px",
      color: "#505050",
    }}
  >
    <h3>Partnership Advantages</h3>
    <ul>
      <li>
        <b>Ease of Formation:</b> A partnership is relatively easy to form with minimal legal formalities.
      </li>
      <li>
        <b>Shared Responsibility:</b> Partners share the responsibilities and decision-making, reducing the burden on a single individual.
      </li>
      <li>
        <b>Access to More Capital:</b> With multiple partners, the business can pool more resources compared to a sole proprietorship.
      </li>
      <li>
        <b>Flexibility:</b> Partnerships offer flexibility in management and operations as partners can mutually agree on terms.
      </li>
    </ul>
  </div>
  <div
    style={{
      width: "90%",
      margin: "40px auto",
      padding: "50px",
      borderTopLeftRadius: "70px",
      background: "#f2f2f2",
    }}
  >
    <div
      style={{
        width: "90%",
        lineHeight: "28px",
        margin: "30px 50px",
        color: "#505050",
      }}
    >
      <h3>Partnership Disadvantages</h3>
      <ul>
        <li>
          <b>Unlimited Liability:</b> Partners are personally liable for the debts and obligations of the partnership.
        </li>
        <li>
          <b>No Separate Legal Entity (if unregistered):</b> An unregistered partnership does not have a separate legal identity, making partners personally liable.
        </li>
        <li>
          <b>Risk of Disputes:</b> Differences in opinion or conflicts among partners can disrupt business operations.
        </li>
        <li>
          <b>Limited Capital:</b> The capital is limited to the contributions of the partners, which may restrict business growth.
        </li>
        <li>
          <b>No Perpetual Succession:</b> The partnership dissolves upon the death, retirement, or insolvency of a partner unless otherwise agreed.
        </li>
      </ul>
    </div>
  </div>
</div>
    </>
  );
}
