import React from 'react'
import { Outlet } from 'react-router-dom'
import CoursesNavbar from '../components/CoursesNavbar'
import Footer from '../components/Footer'

export default function Course() {
    return (
        <>
            <CoursesNavbar/>
            <Outlet />
            <Footer />
        </>
    )
}
