import React from 'react';
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import img_1 from "../../assets/images/class-1.jpg";
import img_2 from "../../assets/images/class-2.jpg";
import img_3 from "../../assets/images/class-4.jpg";
import JoinCommunity from './JoinOurComunity';
export default function Training() {
  const courses = [
    {
      title: "ITP Exam Preparation Course with Income Tax Management",
      description: "Learn the detailed concept of Income Tax with practical examples.",
      image: img_1,
    },
    {
      title: "VAT Management with VAT Consultant Exam Preparation",
      description: "Excellent course to learn VAT management under expert mentors from NBR.",
      image: img_2,
    },
    {
      title: "Certificate Course on VAT & Customs Management",
      description: "This unique course will help VAT professionals know critical issues related to VAT.",
      image: img_3,
    },
    {
      title: "Certificate Course on VAT & Customs Management",
      description: "This unique course will help VAT professionals know critical issues related to VAT.",
      image: img_1,
    },
  ];
  return (
    <>
    <div style={{ backgroundColor: "#4caf50", color: "#fff",borderBottomRightRadius:"150px", padding: "120px 0", textAlign: "center" }}>
      <h2>All Professional Certificate Courses</h2>
    </div>

    <Container className="my-5">
      <Row>
        {courses.map((course, index) => (
          <Col md={3} key={index} className="mb-4">
            <Card>
              <Card.Img variant="top" src={course.image} />
              
             <div style={{borderLeft:"3px solid #f35120",margin:"40px 0px",padding:"0 20px"}}>
             <h5>ITP Exam Preparation Course with Income Tax Management</h5>
             <p>Learn the detailed concept of Income Tax with practical examples under expert mentors from NBR and industry experts to prepare better for ITP exam and change your profession.</p>
             </div>
                <Button style={{ backgroundColor: "#f35120", border: "none", margin:"10px" }}>Learn More</Button>
             
            </Card>
          </Col>
        ))}
      </Row>
    </Container>

    <div>
      <img style={{width:"100%"}} src="https://www.charteredskills.com/media/Banners/big_banner_yMFiA11.webp" alt="" />
    </div>
    <div style={{ backgroundColor: "#4caf50", color: "#fff", marginTop:"20px", padding: "20px 0", textAlign: "center" }}>
      <h2>Certificate</h2>
    </div>
    <Container className="py-5">
      <Row className="align-items-center">
        {/* Left side: Image */}
        <Col md={6} className="text-center" style={{background:"#0000"}}>
          <img
            src="https://www.charteredskills.com/static/images/Certificate_Mockup.webp"
            alt="Certificate Mockup"
            className="img-fluid"
            style={{ maxWidth: "100%"}}
          />
        </Col>

        {/* Right side: Content */}
        <Col md={6} style={{padding:"40px"}}>
          <h4 className="fw-bold">
            Demonstrate your new skills and gain a step-up in your career with a
            JK Associates globally recognized certification.
          </h4>
          <p className='mt-5'>
            JK Associates is a professional & educational platform that provides
            a wide range of skill-oriented courses, helping individuals reach
            their professional goals. Our platform enables entrepreneurs to
            learn industry knowledge, helps job seekers match their skill sets
            with ideal jobs, and allows professionals to remain relevant in
            their industries through upskilling & microlearning.
          </p>
          <img
            src="https://jkassociates.com.bd/static/media/jk%20logo-long.0da57d578ab6ca1ffb16.png"
            alt="JK Associates Logo"
            className="img-fluid mb-3 mt-3"
            style={{ maxWidth: "400px" }}
          />
          <br />
          <Button style={{ backgroundColor: "#4caf50", border: "none" }}>
            Accreditation Info
          </Button>
        </Col>
      </Row>
    </Container>
    <JoinCommunity/>
  </>
  )
}
