import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../components/Footer'
import ResourceNavbar from '../components/ResourceNavbar'

export default function Resource() {

  return (
    <>
    <ResourceNavbar/>
      <Outlet/>
      <Footer/>
    </>
  )
}
