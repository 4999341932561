import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { FaSearch } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';

function SearchForm() {
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedValue,setSelectedValue] = useState(null)
    const navigate = useNavigate();
    const history = useLocation();

    const handleSearchInputChange = (event, { newValue }) => {
        setSearchQuery(newValue);

        // Filter suggestions based on the input query
        const filteredSuggestions = allPages.filter((page) =>
            page.value.toLowerCase().includes(newValue.toLowerCase())
        );

        setSuggestions(filteredSuggestions);
    };

    const handleSearchKeyPress = (event) => {
        if (event.key === 'Enter' && searchQuery.trim() !== '') {
            // Trim the search query and replace spaces with dashes for a clean URL
            const formattedQuery = searchQuery.trim().replace(/\s+/g, '-');
            console.log("formattedQuery",formattedQuery)

            // Construct the URL dynamically and navigate to it
            // navigate(`/${formattedQuery}`);
        }
    };

    const handleSuggestionSelect = (event, { suggestion }) => {
        // Navigate to the selected suggestion
        // const formattedQuery = suggestion.value.trim().replace(/\s+/g, '-');
        const formattedQuery = suggestion.key
        navigate(`/${formattedQuery}`);
        setSelectedValue(formattedQuery)
    };
   useEffect(()=>{
    if(history.pathname === `/${selectedValue}`){
        setSearchQuery("")
    }
   },[history,selectedValue])
    const renderSuggestion = (suggestion) => (
        <div className="suggestions-container">
            <div className="suggestion-item"
                style={{
                    color: 'white',
                    fontSize: '13px',
                    fontWeight: '500',
                    listStyleType: 'none',
                    width: '250px',
                    height: '35px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    backgroundColor: '#4CAF50',  // Background color
                    borderRadius: '10px',  // Rounded corners
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',  // Shadow
                    transition: 'background-color 0.3s ease, transform 0.3s ease',  // Add transition effect
                    margin: '3px',  // Margin for spacing
                    padding: '0 20px',  // Padding for text spacing
                }}

                // Add hover effect
                onMouseOver={(event) => {
                    // Change background color on hover
                    event.target.style.backgroundColor = '#04aa6d';
                }}
                onMouseOut={(event) => {
                    // Revert to original background color on hover out
                    event.target.style.backgroundColor = '#4CAF50';
                }}>
                {suggestion.value}
            </div>
        </div>
    );

    // Manually set keys for the allPages array
    const allPages = [
        { key: 'company-formation-in-bangladesh', value: 'Company Formation in Bangladesh' },
        { key: 'one-person-company-registration-bangladesh', value: 'One Person Company' },
        { key: 'society-registration-in-bangladesh', value: 'Society Registration' },
        { key: 'partnership-registration-in-bangladesh', value: 'Partnership Registration' },
        { key: 'rjsc-return-filing', value: 'RJSC Return Filing' },
        { key: 'loan-consultancy-services', value: 'Loan Consultancy Services' },
        { key: 'import-license-irc', value: 'Import License' },
        { key: 'export-license-erc', value: 'Export License' },
        { key: 'bsti-permissions', value: 'BSTI Permission' },
        { key: 'trade-license', value: 'Trade License' },
        { key: 'project-profile', value: 'Project Profile' },
        { key: 'startup-packages', value: 'Startup Packages' },
        { key: 'trademark-registration-in-bangladesh', value: 'Trademark Registration in Bangladesh' },
        { key: 'copyright-registration', value: 'Copyright Registration' },
        { key: 'patent', value: 'Patent' },
        { key: 'industrial-design', value: 'Industrial Design' },
        { key: 'ipr-enforcement', value: 'IPR Enforcement' },
        { key: 'btcl-dr', value: 'BTCL Domain Registration' },
        { key: 'web-development', value: 'Web Development' },
        { key: 'income-tax', value: 'Income Tax' },
        { key: 'corporate-tax', value: 'Corporate Tax' },
        { key: 'vat-online-service', value: 'VAT Online Service' },
        { key: 'tax-identification-number-e-tin', value: 'E-TIN Certificates' },
        { key: 'vat-registration-certificate-in-bangladesh', value: 'VAT Registration Certificate' },
        { key: 'account-service', value: 'Account Service' },
        { key: 'bookkeeping-services', value: 'Bookkeeping Services' },
        { key: 'small-business-in-bangladesh', value: 'Small Business in Bangladesh' },
        { key: 'acounting-software', value: 'Accounting Software' },
        { key: 'erp-software', value: 'ERP Software' },
        { key: 'vat-software', value: 'VAT Software' },
        { key: 'hr-management-software', value: 'HR Management Software' },
        { key: 'company-portfolio', value: 'Company Portfolio' },
        { key: 'civil-litigation', value: 'Civil Litigation' },
        { key: 'criminal-litigation', value: 'Criminal Litigation' },
        { key: 'foreign-company-registration-in-bangladesh', value: 'Foreign Company Registration in Bangladesh' },
        { key: 'foreign-direct-investment', value: 'Foreign Direct Investment' },
        { key: 'bida-permission', value: 'BIDA Permission' },
        { key: 'work-permit-visa', value: 'Work Permit VISA' },
        { key: 'private-investment-visa', value: 'Private Investment VISA' },
        { key: 'foreign-delegates', value: 'Foreign Delegates' },
        { key: 'foreign-company-director-service', value: 'Foreign Company Director Service' },
        { key: 'foreign-company-registered-address', value: 'Foreign Company Registered Address' },
        { key: 'fire-license', value: 'Fire License' },
        { key: 'factory-license', value: 'Factory License' },
        { key: 'customs-bond-license', value: 'Customs Bond License' },
        { key: 'environment-clearance-certificate', value: 'Environment Clearance Certificate' },
        { key: 'company-winding-up', value: 'Company Winding Up' },
        { key: 'buying-house-licensing-in-bangladesh', value: 'Buying House Licensing in Bangladesh' },
        { key: 'textile-dot-license', value: 'Textile (DoT) License' },
        { key: 'epb-license', value: 'EPB License' },
        { key: 'about-us', value: 'About Us' },
        { key: 'payment-process', value: 'Payment Process' },
        { key: 'refund-policy', value: 'Refund Policy' },
        { key: 'privacy-policy', value: 'Privacy Policy' },
        { key: 'term-condition', value: 'Terms & Conditions' },
        { key: 'contact-us', value: 'Contact Us' },
        { key: 'sitemap', value: 'Sitemap' },
        { key: 'vat-calculator', value: 'VAT Calculator' },
        { key: 'visa-consultancy', value: 'Visa Consultancy' },
        { key: 'property-consultancy', value: 'Property Consultancy' },
        { key: 'co-space', value: 'Co-Space' },
        { key: 'company-formation-form-page', value: 'Company Formation Form Page' },
        { key: 'share-transfer', value: 'Share Transfer' },
        { key: 'share-allotment', value: 'Share Allotment' },
        { key: 'share-transmission', value: 'Share Transmission' },
        { key: 'registered-address-change', value: 'Registered Address Change' },
        { key: 'AGM-high-court-permission', value: 'AGM high court permission' },
        { key: 'court-succession-certificate', value: 'court succession certificate' },
        { key: 'resource/why-investment-in-bangladesh', value: 'Why Investment in Bangladesh' },
        { key: 'resource/blog', value: 'Blog' },
        { key: 'resource/proprietorship', value: 'Proprietorship' },
        { key: 'resource/partnership', value: 'Partnership' },
        { key: 'resource/company-registration-bd', value: 'Company Registration in Bangladesh' },
        { key: 'resource/family-office', value: 'Family Office' },
        { key: 'resource/headquarters', value: 'Headquarters' },
        { key: 'resource/manufactures', value: 'Manufacturers' },
        { key: 'resource/multinational-corporations', value: 'Multinational Corporations' },
        { key: 'resource/vc-pc-companies', value: 'VC/PC Companies' },
        { key: 'resource/vision-2040-bangladesh', value: 'Vision 2040 Bangladesh' },
        { key: 'resource/technology-and-ict', value: 'Technology and ICT' },
        { key: 'resource/construction-and-real-estate', value: 'Construction and Real Estate' },
        { key: 'resource/healthcare-and-pharmaceuticals', value: 'Healthcare and Pharmaceuticals' },
        { key: 'resource/food-and-agriculture', value: 'Food and Agriculture' },
        { key: 'resource/energy-and-cleantech', value: 'Energy and Cleantech' },
        { key: 'resource/finance-and-wealth', value: 'Finance and Wealth' },
        { key: 'resource/retail-and-ecommerce', value: 'Retail and E-commerce' },
        { key: 'resource/trade-and-logistics', value: 'Trade and Logistics' },
        { key: 'resource/design-media-and-entertainment', value: 'Design, Media, and Entertainment' },
        { key: 'resource/company-set-up-options', value: 'Company Set-Up Options' },
        { key: 'resource/mainland-companies', value: 'Mainland Companies' },
        { key: 'resource/free-zone-companies', value: 'Free Zone Companies' },
        { key: 'resource/employees-and-administration', value: 'Employees and Administration' },
        { key: 'resource/regulations-laws-and-tax', value: 'Regulations, Laws, and Tax' },
        { key: 'resource/support-for-smes', value: 'Support for SMEs' },
        { key: 'resource/support-for-manufacturers-and-exporters', value: 'Support for Manufacturers and Exporters' },
        { key: 'resource/bangladesh-unified-licence', value: 'Bangladesh Unified Licence' },
        { key: 'resource/branch-office-bd', value: 'Branch Office BD' },
        { key: 'resource/business-licenses-bangladesh', value: 'Business Licenses in Bangladesh' },
        { key: 'resource/visas-and-entry', value: 'Visas and Entry' },
        { key: 'resource/get-a-job', value: 'Get a Job' },
        { key: 'resource/open-a-bank-account', value: 'Open a Bank Account' },
        { key: 'resource/introduction-to-real-estate', value: 'Introduction to Real Estate' },
        { key: 'resource/roads-and-driving', value: 'Roads and Driving' },
        { key: 'resource/public-transport', value: 'Public Transport' },
        { key: 'resource/education-ecosystem', value: 'Education Ecosystem' },
        { key: 'resource/health-and-wellness', value: 'Health and Wellness' },
        { key: 'resource/resources-for-city-living', value: 'Resources for City Living' },
        { key: 'resource/work-remotely', value: 'Work Remotely' },
        { key: 'resource/legal-system', value: 'Legal System' },
        { key: 'resource/foreign-invest-bd', value: 'Foreign Investment in Bangladesh' }
    ];
    

    const inputProps = {
        placeholder: 'Search a service..',
        value: searchQuery,
        onChange: handleSearchInputChange,
        onKeyPress: handleSearchKeyPress,
        className: 'form-control me-2 my-3',
        style: { paddingLeft: '30px', borderRadius: '10px', color: '#4CAF50', border: '2px solid #4CAF50' }

    };

    return (
        <div style={{ marginTop: '4px', fontSize: '14px', padding: '2px', width: '200px', height: '40px', position: 'relative' }}>
            <FaSearch style={{ position: 'absolute', left: '10px', top: '90%', transform: 'translateY(-50%)', color: '#4CAF50' }} />
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={() => { }}
                onSuggestionsClearRequested={() => setSuggestions([])}
                getSuggestionValue={(suggestion) => suggestion.value}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={handleSuggestionSelect}
                inputProps={inputProps}
            />
        </div>
    );
}

export default SearchForm;
