import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "./Meta";
export default function LegalSystem() {
  return (
    <>
      <Meta
        title="Liaison or Branch Office in Bangladesh | Branch Office Registration"
        description="In Bangladesh, a branch office and a liaison office are both legal entities that are extensions of a foreign company. However, they have different purposes and activities, like oppration overview and inspection workoder with prior approval from the Board of Investment (BIDA)"
        image={"image will be here"}
        keywords="Liaison Office, Branch Office, Liaison Office in Bangladesh, Branch Office in Bangladesh, Liaison buying house in Bangladesh, Branch office registration in Bangladesh, Apparel Liaison Office in Bangladesh"
      />
      <div className="container-xxl" style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Legal system of Bangladesh
          </h1>
        </div>
      </div>
      {/* header img */}
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="Working in Dubai banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>
      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <p style={{ lineHeight: "28px" }}>
          Bangladesh is a common law based jurisdiction. Many of the basic laws of Bangladesh such as penal code, civil and criminal procedural codes, contract law and company law are influenced by English common laws. However family laws such as laws relating to marriage, dissolution of marriage and inheritance are based on religious scripts, and therefore differ between religious communities.
          </p>
          <p style={{ lineHeight: "28px" }}>
          The Bangladeshi legal system is based on a written constitution and the laws often take statutory forms which are enacted by the legislature and interpreted by the higher courts. Ordinarily executive authorities and statutory corporations cannot make any law, but can make by-laws to the extent authorized by the legislature. Such subordinate legislation is known as rules or regulations and is also enforceable by the court. Yet being a common law system, the statutes are short, and set out basic rights and responsibilities but are elaborated by the courts in their application and interpretation of those. In addition, certain customs and practices established over a period are also to some extent recognized as acceptable and are enforced by the courts.
          </p>
          <p>
          Since 1971 Bangladesh’s legal system has been updated in areas of company, banking, bankruptcy and Money Loan Court laws and other commercial laws.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Courts in Bangladesh</h3>
         <p>
         The Judiciary of Bangladesh acts through the (i) The Superior Judiciary having Appellate, Revision & Original Jurisdiction & (2) Sub-Ordinate Judiciary having Original Jurisdiction
         </p>
          <h3>The Supreme Court</h3>
         <p>
         Bangladesh Supreme Court is the highest court of Bangladesh, have two divisions:
         </p>
         <ul>
          <li>Appellate Division</li>
          <li>High Court Division</li>
         </ul>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Documents Required</h3>
          <p>
            The following documents are generally required for approval of a
            Branch, Liaison, or Project Office in Bangladesh:
          </p>
          <ul>
            <li>Board of Directors’ resolution from the parent company.</li>
            <li>
              Audited accounts of the parent company for the last financial
              year.
            </li>
            <li>Proposed organogram of the office.</li>
            <li>
              Memorandum and Articles of Association of the parent company.
            </li>
            <li>Certificate of Incorporation of the parent company.</li>
            <li>Details of activities to be performed in Bangladesh.</li>
            <li>
              Forwarding letter and authorization letter from the parent
              company.
            </li>
          </ul>
          <p>
            <b>Note:</b> Documents must be attested by the concerned Bangladesh
            mission or local business chamber. Non-English documents must be
            translated into English.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Registration Procedure and Timeline</h3>
          <ol>
            <li>
              <b>Step 1 - Online Application:</b> Upload all required documents
              to BIDA’s online portal and submit physical copies.
            </li>
            <li>
              <b>Step 2 - Inter-Ministerial Committee Review:</b> BIDA officials
              will review the documents and may request additional information
              or a physical presentation.
            </li>
            <li>
              <b>Step 3 - Approval:</b> If satisfied, the committee will grant
              approval, usually within one month.
            </li>
            <li>
              <b>Step 4 - Bank Account Opening:</b> Open a bank account in
              Bangladesh and remit at least US$ 50,000 within two months of
              approval.
            </li>
            <li>
              <b>Step 5 - Notification to Bangladesh Bank:</b> Report to
              Bangladesh Bank within 30 days of approval.
            </li>
            <li>
              <b>Step 6 - RJSC Registration:</b> Register with the Registrar of
              Joint Stock Companies and Firms (RJSC) after obtaining approval
              from BIDA and Bangladesh Bank.
            </li>
          </ol>
        </div>
        <div
          style={{
            width: "90%",
            margin: "40px auto",
            padding: "50px",
            borderTopLeftRadius: "70px",
            background: "#f2f2f2",
          }}
        >
          <div
            style={{
              width: "90%",
              lineHeight: "28px",
              margin: "30px 50px",
              color: "#505050",
            }}
          >
            <h3>Advantages of Liaison, Branch, or Project Office</h3>
            <ul>
              <li>
                <b>Quick Setup:</b> These offices can be established faster than
                a subsidiary company.
              </li>
              <li>
                <b>Cost-Effective:</b> Lower initial costs compared to
                incorporating a subsidiary.
              </li>
              <li>
                <b>Market Exploration:</b> Ideal for testing the market before
                making a long-term commitment.
              </li>
              <li>
                <b>Parent Company Control:</b> The parent company retains full
                control over operations.
              </li>
            </ul>
          </div>
        </div>
        <div
          style={{
            width: "90%",
            margin: "40px auto",
            padding: "50px",
            borderTopLeftRadius: "70px",
            background: "#f2f2f2",
          }}
        >
          <div
            style={{
              width: "90%",
              lineHeight: "28px",
              margin: "30px 50px",
              color: "#505050",
            }}
          >
            <h3>Challenges and Considerations</h3>
            <ul>
              <li>
                <b>Limited Activities:</b> Liaison Offices are restricted to
                non-commercial activities.
              </li>
              <li>
                <b>Renewal Requirements:</b> Offices must be renewed
                periodically, which can be time-consuming.
              </li>
              <li>
                <b>Tax Compliance:</b> Offices must comply with local tax laws,
                including TDS and VAT.
              </li>
              <li>
                <b>Foreign Exchange Regulations:</b> Strict rules govern inward
                and outward remittances.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
