import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import company_Formation_og_img from "../assets/og-img/company-formation.png";
import Meta from "./Meta";

export default function CompanyRegistrationInBD() {
  return (
    <>
      <Meta
        title="Company Registration in Bangladesh | How to register a company"
        description="How to register a company in Bangladesh. A private limited company steps is Name Clearance. Opening Bank Account [ for foreign companies only and remittance of paid-up capital] , Article of Association (AoA)"
        image={company_Formation_og_img}
        keywords="register a company in Bangladesh, Company Formation, Company Registration, Company Registration Bangladesh, private limited company, private limited company steps"
      />
     <div className="container-xxl" style={{ padding: "7% 7% 0 7%" }}>
  <div>
    <h1 style={{ marginBottom: "20px", color: "black" }} className="display-6">
      Company Registration in Bangladesh
    </h1>
  </div>
</div>
{/* header img */}
<div style={{ marginTop: "40px" }}>
  <img
    src={homeOfficeImg}
    alt="Working in Dubai banner"
    width={"100%"}
    style={{ borderBottomRightRadius: "137px" }}
  />
</div>
<div
  style={{
    background: "#ececed",
    padding: "30px 0",
    marginBottom: "40px",
    width: "90%",
    float: "inline-end",
    borderBottomLeftRadius: "85px",
  }}
>
  <div style={{ width: "80%", margin: "auto" }}>
    <p style={{ lineHeight: "28px" }}>
      Welcome to our comprehensive guide on registering a private limited company in Bangladesh with foreign shareholders. This guide provides detailed insights into the requirements, procedures, and timelines involved in setting up your business entity in Bangladesh, ensuring compliance with the country’s regulatory framework.
    </p>
    <p style={{ lineHeight: "28px" }}>
      A private limited company in Bangladesh is a separate legal entity, and shareholders are not liable for the company’s debts beyond their share capital. This guide will walk you through the steps, documentation, and regulatory landscape for a smooth company registration process.
    </p>
  </div>
</div>
<div
  style={{
    width: "90%",
    margin: "40px auto",
    padding: "50px",
    borderTopLeftRadius: "70px",
  }}
>
  <div
    style={{
      width: "90%",
      lineHeight: "28px",
      margin: "30px 50px",
      color: "#505050",
    }}
  >
    <h3>Pre-Registration – Key Facts</h3>
    <ul>
      <li>
        <b>Registrar of Joint Stock Companies and Firms (RJSC):</b> The primary regulatory body responsible for company registration and maintenance of records in Bangladesh.
      </li>
      <li>
        <b>Company Name Approval:</b> The proposed company name must be approved by RJSC before incorporation.
      </li>
      <li>
        <b>Directors:</b> A minimum of two directors is required, who can be local or foreign individuals. Directors must be at least 18 years old and free from bankruptcy or criminal convictions.
      </li>
      <li>
        <b>Shareholders:</b> A private limited company can have 2 to 50 shareholders, including individuals or legal entities. 100% foreign shareholding is allowed in most sectors.
      </li>
      <li>
        <b>Authorized Capital:</b> The maximum amount of share capital the company is authorized to issue. There is no minimum or maximum limit.
      </li>
      <li>
        <b>Paid-up Capital:</b> The minimum paid-up capital for registration is Taka 1, which can be increased post-incorporation.
      </li>
      <li>
        <b>Registered Address:</b> A local physical address (residential or commercial) is required for registration.
      </li>
      <li>
        <b>Memorandum and Articles of Association (MoA & AoA):</b> Essential documents outlining the company’s operational and governance framework.
      </li>
      <li>
        <b>Bank Account:</b> A bank account must be opened in the proposed company’s name before incorporation.
      </li>
      <li>
        <b>Initial Capital Deposit:</b> Foreign shareholders must remit the initial capital into the company’s bank account, and an Encashment Certificate will be issued.
      </li>
    </ul>
  </div>
</div>
<div
  style={{
    width: "90%",
    margin: "40px auto",
    padding: "50px",
    borderTopLeftRadius: "70px",
    background: "#d0e4eb",
  }}
>
  <div
    style={{
      width: "90%",
      lineHeight: "28px",
      margin: "30px 50px",
      color: "#505050",
    }}
  >
    <h3>Documents Required and Registration Procedure</h3>
    <p>
      To register a private limited company in Bangladesh, the following documents and information are required:
    </p>
    <ul>
      <li>Name Clearance issued by RJSC</li>
      <li>Memorandum of Association (MoA) and Articles of Association (AoA)</li>
      <li>Particulars of shareholders and directors (name, address, passport, photo)</li>
      <li>Registered address (local residential or commercial address)</li>
      <li>Signed Form IX and Subscriber Page</li>
      <li>Encashment Certificate issued by the bank</li>
    </ul>
    <h4>Registration Steps:</h4>
    <ol>
      <li>
        <b>Step 1 - Name Clearance:</b> Apply for name clearance on the RJSC website and pay the required fee.
      </li>
      <li>
        <b>Step 2 - Bank Account Opening:</b> Open a bank account in the proposed company’s name and remit the initial capital.
      </li>
      <li>
        <b>Step 3 - Document Submission:</b> Upload required documents (Form IX, Subscriber Page, Encashment Certificate) on the RJSC website and pay government fees.
      </li>
      <li>
        <b>Step 4 - Verification:</b> RJSC verifies the Encashment Certificate with the bank.
      </li>
      <li>
        <b>Step 5 - Incorporation:</b> RJSC issues the Certificate of Incorporation, MoA, AoA, and Form XII.
      </li>
    </ol>
  </div>
</div>
<div
  style={{
    width: "90%",
    margin: "40px auto",
    padding: "50px",
    borderTopLeftRadius: "70px",
  }}
>
  <div
    style={{
      width: "90%",
      lineHeight: "28px",
      margin: "30px 50px",
      color: "#505050",
    }}
  >
    <h3>Post-Registration Requirements</h3>
    <ul>
      <li>
        <b>Trade License and Tax Identification Number (TIN):</b> Apply for a trade license and TIN after incorporation.
      </li>
      <li>
        <b>Additional Licenses:</b> Depending on the business activities, additional licenses may be required.
      </li>
      <li>
        <b>Annual Return Filing:</b> Hold an Annual General Meeting (AGM) and file annual returns with RJSC.
      </li>
      <li>
        <b>Regular Return Filing:</b> File returns for any changes in directors, shareholders, or other details.
      </li>
    </ul>
  </div>
  <div
    style={{
      width: "90%",
      margin: "40px auto",
      padding: "50px",
      borderTopLeftRadius: "70px",
      background: "#f2f2f2",
    }}
  >
    <div
      style={{
        width: "90%",
        lineHeight: "28px",
        margin: "30px 50px",
        color: "#505050",
      }}
    >
      <h3>Advantages of a Private Limited Company</h3>
      <ul>
        <li>
          <b>Separate Legal Entity:</b> The company is distinct from its shareholders, offering limited liability protection.
        </li>
        <li>
          <b>100% Foreign Ownership:</b> Most sectors allow full foreign ownership.
        </li>
        <li>
          <b>Ease of Capital Raising:</b> Shares can be issued or transferred to raise capital.
        </li>
        <li>
          <b>Perpetual Succession:</b> The company continues to exist regardless of changes in ownership or management.
        </li>
      </ul>
    </div>
  </div>
  <div
    style={{
      width: "90%",
      margin: "40px auto",
      padding: "50px",
      borderTopLeftRadius: "70px",
      background: "#f2f2f2",
    }}
  >
    <div
      style={{
        width: "90%",
        lineHeight: "28px",
        margin: "30px 50px",
        color: "#505050",
      }}
    >
      <h3>Challenges and Considerations</h3>
      <ul>
        <li>
          <b>Bank Account Opening:</b> Foreign shareholders may face difficulties due to unfamiliarity with the process.
        </li>
        <li>
          <b>Regulatory Compliance:</b> Annual and regular return filings are mandatory.
        </li>
        <li>
          <b>Initial Capital Requirement:</b> Foreign shareholders must remit initial capital before incorporation.
        </li>
        <li>
          <b>Visa and Work Permit:</b> Foreign investors may need a business visa or work permit if relocating to Bangladesh.
        </li>
      </ul>
    </div>
  </div>
</div>
    </>
  );
}
