import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "./Meta";
export default function BranchOfficeInBd() {
  return (
    <>
      <Meta
        title="Liaison or Branch Office in Bangladesh | Branch Office Registration"
        description="In Bangladesh, a branch office and a liaison office are both legal entities that are extensions of a foreign company. However, they have different purposes and activities, like oppration overview and inspection workoder with prior approval from the Board of Investment (BIDA)"
        image={"image will be here"}
        keywords="Liaison Office, Branch Office, Liaison Office in Bangladesh, Branch Office in Bangladesh, Liaison buying house in Bangladesh, Branch office registration in Bangladesh, Apparel Liaison Office in Bangladesh"
      />
     <div className="container-xxl" style={{ padding: "7% 7% 0 7%" }}>
  <div>
    <h1 style={{ marginBottom: "20px", color: "black" }} className="display-6">
      Liaison, Branch, or Project Office in Bangladesh
    </h1>
  </div>
</div>
{/* header img */}
<div style={{ marginTop: "40px" }}>
  <img
    src={homeOfficeImg}
    alt="Working in Dubai banner"
    width={"100%"}
    style={{ borderBottomRightRadius: "137px" }}
  />
</div>
<div
  style={{
    background: "#ececed",
    padding: "30px 0",
    marginBottom: "40px",
    width: "90%",
    float: "inline-end",
    borderBottomLeftRadius: "85px",
  }}
>
  <div style={{ width: "80%", margin: "auto" }}>
    <p style={{ lineHeight: "28px" }}>
      A Branch, Liaison, or Project Office is one of the two options for foreign investors wishing to establish their presence in Bangladesh. These offices are often set up as temporary extensions of the parent company and are not separate legal entities. They are typically used for specific projects or to explore new markets.
    </p>
    <p style={{ lineHeight: "28px" }}>
      This guide provides detailed insights into the requirements, procedures, and regulatory landscape for setting up a Liaison, Branch, or Project Office in Bangladesh, ensuring compliance with the country’s regulatory framework.
    </p>
  </div>
</div>
<div
  style={{
    width: "90%",
    margin: "40px auto",
    padding: "50px",
    borderTopLeftRadius: "70px",
  }}
>
  <div
    style={{
      width: "90%",
      lineHeight: "28px",
      margin: "30px 50px",
      color: "#505050",
    }}
  >
    <h3>Key Considerations for Liaison, Branch, and Project Office</h3>
    <ul>
      <li>
        <b>Liaison Office:</b> A Liaison Office can only perform limited activities such as maintaining coordination between the parent company and local agents, and collecting business information. It cannot generate local income and must rely on remittances from the parent company.
      </li>
      <li>
        <b>Project Office:</b> Introduced by BIDA in 2025, a Project Office is established for implementing government or development projects. Approval requires a recommendation from the implementing agency.
      </li>
      <li>
        <b>Branch Office:</b> A Branch Office can engage in business activities as approved by BIDA. It must adhere to the terms outlined in the approval letter.
      </li>
      <li>
        <b>Duration and Renewal:</b> The duration of these offices is confined to the period mentioned in the approval letter. Renewal applications must be submitted at least two months before expiry.
      </li>
      <li>
        <b>Foreign Exchange Requirements:</b> An inward remittance of at least US$ 50,000 must be brought into Bangladesh within two months of approval.
      </li>
      <li>
        <b>Tax and Compliance:</b> Offices must comply with tax regulations, including TIN and BIN registration, and submit quarterly income and expenditure reports.
      </li>
    </ul>
  </div>
</div>
<div
  style={{
    width: "90%",
    margin: "40px auto",
    padding: "50px",
    borderTopLeftRadius: "70px",
    background: "#d0e4eb",
  }}
>
  <div
    style={{
      width: "90%",
      lineHeight: "28px",
      margin: "30px 50px",
      color: "#505050",
    }}
  >
    <h3>Documents Required</h3>
    <p>
      The following documents are generally required for approval of a Branch, Liaison, or Project Office in Bangladesh:
    </p>
    <ul>
      <li>Board of Directors’ resolution from the parent company.</li>
      <li>Audited accounts of the parent company for the last financial year.</li>
      <li>Proposed organogram of the office.</li>
      <li>Memorandum and Articles of Association of the parent company.</li>
      <li>Certificate of Incorporation of the parent company.</li>
      <li>Details of activities to be performed in Bangladesh.</li>
      <li>Forwarding letter and authorization letter from the parent company.</li>
    </ul>
    <p>
      <b>Note:</b> Documents must be attested by the concerned Bangladesh mission or local business chamber. Non-English documents must be translated into English.
    </p>
  </div>
</div>
<div
  style={{
    width: "90%",
    margin: "40px auto",
    padding: "50px",
    borderTopLeftRadius: "70px",
  }}
>
  <div
    style={{
      width: "90%",
      lineHeight: "28px",
      margin: "30px 50px",
      color: "#505050",
    }}
  >
    <h3>Registration Procedure and Timeline</h3>
    <ol>
      <li>
        <b>Step 1 - Online Application:</b> Upload all required documents to BIDA’s online portal and submit physical copies.
      </li>
      <li>
        <b>Step 2 - Inter-Ministerial Committee Review:</b> BIDA officials will review the documents and may request additional information or a physical presentation.
      </li>
      <li>
        <b>Step 3 - Approval:</b> If satisfied, the committee will grant approval, usually within one month.
      </li>
      <li>
        <b>Step 4 - Bank Account Opening:</b> Open a bank account in Bangladesh and remit at least US$ 50,000 within two months of approval.
      </li>
      <li>
        <b>Step 5 - Notification to Bangladesh Bank:</b> Report to Bangladesh Bank within 30 days of approval.
      </li>
      <li>
        <b>Step 6 - RJSC Registration:</b> Register with the Registrar of Joint Stock Companies and Firms (RJSC) after obtaining approval from BIDA and Bangladesh Bank.
      </li>
    </ol>
  </div>
  <div
    style={{
      width: "90%",
      margin: "40px auto",
      padding: "50px",
      borderTopLeftRadius: "70px",
      background: "#f2f2f2",
    }}
  >
    <div
      style={{
        width: "90%",
        lineHeight: "28px",
        margin: "30px 50px",
        color: "#505050",
      }}
    >
      <h3>Advantages of Liaison, Branch, or Project Office</h3>
      <ul>
        <li>
          <b>Quick Setup:</b> These offices can be established faster than a subsidiary company.
        </li>
        <li>
          <b>Cost-Effective:</b> Lower initial costs compared to incorporating a subsidiary.
        </li>
        <li>
          <b>Market Exploration:</b> Ideal for testing the market before making a long-term commitment.
        </li>
        <li>
          <b>Parent Company Control:</b> The parent company retains full control over operations.
        </li>
      </ul>
    </div>
  </div>
  <div
    style={{
      width: "90%",
      margin: "40px auto",
      padding: "50px",
      borderTopLeftRadius: "70px",
      background: "#f2f2f2",
    }}
  >
    <div
      style={{
        width: "90%",
        lineHeight: "28px",
        margin: "30px 50px",
        color: "#505050",
      }}
    >
      <h3>Challenges and Considerations</h3>
      <ul>
        <li>
          <b>Limited Activities:</b> Liaison Offices are restricted to non-commercial activities.
        </li>
        <li>
          <b>Renewal Requirements:</b> Offices must be renewed periodically, which can be time-consuming.
        </li>
        <li>
          <b>Tax Compliance:</b> Offices must comply with local tax laws, including TDS and VAT.
        </li>
        <li>
          <b>Foreign Exchange Regulations:</b> Strict rules govern inward and outward remittances.
        </li>
      </ul>
    </div>
  </div>
</div>
    </>
  );
}
