import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "./Meta";

export default function LicenseAndApproval() {
  return (
    <>
      <Meta
        title="Business License in Bangladesh | Business Certificates"
        description="a list of business licenses/ approvals/ permits/ certificates, which are most frequently required for doing business in Bangladesh. Please note that there are some other licenses/approvals/permits, which are not mentioned here. Unless you are conducting your business as a proprietorship, you will be required to register a Partnership or Company prior to obtaining these licenses/approvals/permits/certificate."
        image={"image will be here"}
        keywords="business licenses in bangladesh, Business Certificates, Business license in bangladesh requirements for foreigners"
      />
     <div className="container-xxl" style={{ padding: "7% 7% 0 7%" }}>
  <div>
    <h1 style={{ marginBottom: "20px", color: "black" }} className="display-6">
      Business License and Business Certificates Approval in Bangladesh
    </h1>
  </div>
</div>
{/* header img */}
<div style={{ marginTop: "40px" }}>
  <img
    src={homeOfficeImg}
    alt="Working in Dubai banner"
    width={"100%"}
    style={{ borderBottomRightRadius: "137px" }}
  />
</div>
<div
  style={{
    background: "#ececed",
    padding: "30px 0",
    marginBottom: "40px",
    width: "90%",
    float: "inline-end",
    borderBottomLeftRadius: "85px",
  }}
>
  <div style={{ width: "80%", margin: "auto" }}>
    <p style={{ lineHeight: "28px" }}>
      Below is a list of licenses, approvals, permits, and certificates frequently required for doing business in Bangladesh. Please note that this list is not exhaustive, and additional licenses or approvals may be required depending on the nature of your business.
    </p>
    <p style={{ lineHeight: "28px" }}>
      Unless you are conducting your business as a proprietorship, you will be required to register a Partnership or Company prior to obtaining these licenses, approvals, permits, or certificates.
    </p>
  </div>
</div>
<div
  style={{
    width: "90%",
    margin: "40px auto",
    padding: "50px",
    borderTopLeftRadius: "70px",
  }}
>
  <div
    style={{
      width: "90%",
      lineHeight: "28px",
      margin: "30px 50px",
      color: "#505050",
    }}
  >
    <h3>Required Business Licenses and Approvals</h3>
    <table style={{ width: "100%", borderCollapse: "collapse" }}>
      <thead>
        <tr style={{ backgroundColor: "#f2f2f2" }}>
          <th style={{ padding: "12px", border: "1px solid #ddd", textAlign: "left" }}>
            Required Business Licenses for Bangladesh
          </th>
          <th style={{ padding: "12px", border: "1px solid #ddd", textAlign: "left" }}>
            Licenses Approval Authority
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Trade License</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>All City Corporations, Municipalities & Union Parisads</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Tax Identification Number (TIN) Individual</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>National Board of Revenue (NBR)</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Incorporation Certificate for Limited Company</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Registrar of Joint Stock Companies and Firms</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Incorporation Certificate for Partnership Firms</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Registrar of Joint Stock Companies and Firms</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Tax Identification Number (TIN) For Entities</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>National Board of Revenue (NBR)</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>VAT Registration Certificate</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>National Board of Revenue (NBR)</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Association Membership Certificate</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Government Approved Association (BMEA, BKMEA, DCCI, FBCCI, etc.)</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Import Registration Certificate (IRC)</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Office of the Chief Controller of Imports & Exports (CCI&E)</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Export Registration Certificate (ERC)</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Office of the Chief Controller of Imports & Exports (CCI&E)</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Ad-hoc IRC/ BIDA Recommendation / BIDA Suparishnama</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Bangladesh Investment Development Authority (BIDA)</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Indenting Registration Certificate (IRC)</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Office of the Chief Controller of Imports & Exports (CCI&E)</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Premises License</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Civil Surgeon Office</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Quality Certification Mark</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Bangladesh Standard and Testing Institution (BSTI)</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Approval of Factory Plan</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Department of Inspection for Factories and Establishments (DIFE)</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Certificate of Registration of Factories and Establishment</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Department of Inspection for Factories and Establishments (DIFE)</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Bonded Warehouse License</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Customs Bond Commissionerate (CBC)</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Explosives Licenses</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Department of Explosives</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Registration Certificate from Inspector of Boiler</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Office of the Chief Inspector of Boiler (CIOB)</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Environmental Clearance Certificate</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Department of Environment (DOE)</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Fire License</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Fire Service and Civil Defense (FS&CD)</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Acid License</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Deputy Commissioner (DC) of all Districts</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Construction Certificate</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>RAJUK/CDA/KDA</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Registration Certificate of Designs</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Department of Patent, Design and Trade Marks (DPDT)</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Registration Certificate of Patent</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Department of Patent, Design and Trade Marks (DPDT)</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Trade Marks Registration</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Department of Patents, Designs & Trademarks (DPDT)</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Copyright Registration</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Copyright Office</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Project Registration with BIDA</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Bangladesh Investment Development Authority (BIDA)</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Work Permit for Foreign Nationals</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>BIDA and BEPZA</td>
        </tr>
        <tr>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Approval of Foreign Loan</td>
          <td style={{ padding: "12px", border: "1px solid #ddd" }}>Bangladesh Investment Development Authority (BIDA)</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
    </>
  );
}
