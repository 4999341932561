import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { FaUserClock } from "react-icons/fa";
import { GiDuration } from "react-icons/gi";
import { PiStudentBold } from "react-icons/pi";
import { RiCalendarScheduleFill } from "react-icons/ri";
import { VscFeedback } from "react-icons/vsc";
import BottomRadius from "../ui/BottomRadius";
import Certificates from "../ui/Certificates";
import TopLeftRadiusSection from "../ui/TopLeftRadiusSection";

export default function TaxationTraining() {
  const [activeKey, setActiveKey] = useState("courseOverview");
  const instructors = [
    {
      name: "John Doe",
      role: "Senior VAT Consultant",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRppsWFFb1GMG5B0zGQCoV-CHns1US-PhLwMw&s",
    },
    {
      name: "Jane Smith",
      role: "Taxation & Compliance Expert",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRppsWFFb1GMG5B0zGQCoV-CHns1US-PhLwMw&s",
    },
    {
      name: "Michael Lee",
      role: "VAT Policy Analyst",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRppsWFFb1GMG5B0zGQCoV-CHns1US-PhLwMw&s",
    },
  ];
  return (
    <>
      <div
        style={{
          backgroundColor: "#4caf50",
          color: "#fff",
          borderBottomRightRadius: "150px",
          padding: "120px 0",
          textAlign: "center",
        }}
      >
        <h2>Professional Taxation Training</h2>
      </div>
      <BottomRadius>
        <Tabs
          id="uncontrolled-tab-example"
          activeKey={activeKey}
          onSelect={(k) => setActiveKey(k)}
          className="mb-3"
        >
          <Tab eventKey="courseOverview" title={<span>Overview</span>}>
            <h3 className="mt-4">Course Overview</h3>
            <div className="mt-4">
              <p>
                <b>
                  This diploma course is jointly organized by Chartered Skills &
                  United International University. Training will be held on
                  United International University (UIU) Campus.
                </b>
              </p>
              <p className="mt-3">
                Postgraduate Diploma in Project Management (PGDPM) has been
                specifically designed as an advanced course for working
                professionals who want to take on challenges as highly effective
                project leaders. The Project Management course also focuses on
                project management methodology that will allow you to initiate
                and manage a project efficiently and effectively. Participants
                will learn key project management skills and strategies.
              </p>
            </div>
          </Tab>
          <Tab eventKey="outline" title={<span>Outline</span>}>
            <h3 className="mt-4">Course Outline</h3>
            <div className="mt-4">
              <p>
                <b>
                  This diploma course is jointly organized by Chartered Skills &
                  United International University. Training will be held on
                  United International University (UIU) Campus.
                </b>
              </p>
              <p className="mt-3">
                Postgraduate Diploma in Project Management (PGDPM) has been
                specifically designed as an advanced course for working
                professionals who want to take on challenges as highly effective
                project leaders. The Project Management course also focuses on
                project management methodology that will allow you to initiate
                and manage a project efficiently and effectively. Participants
                will learn key project management skills and strategies.
              </p>
            </div>
          </Tab>
        </Tabs>
      </BottomRadius>
      <div className="resource-container">
        <h2 className="resource-section-title">Course Outline</h2>
        <hr />
        <div className="resource-card-container">
          <div className="resource-card">
            <div className="resource-icon-circle">
              {/* <span className="resource-icon">&#x1F3E0;</span> */}
              <PiStudentBold style={{ fontSize: "30px", color: "#4caf50" }} />
            </div>
            <h4>Total Class:10+</h4>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <FaUserClock style={{ fontSize: "30px", color: "#4caf50" }} />
            </div>
            <h4>Time: 08:00 PM - 10:00 PM</h4>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <GiDuration style={{ fontSize: "30px", color: "#4caf50" }} />
            </div>
            <h4>Class Duration : 2H+</h4>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              {" "}
              <RiCalendarScheduleFill
                style={{ fontSize: "30px", color: "#4caf50" }}
              />
            </div>
            <h4>Day : Friday and Saturday</h4>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <VscFeedback 
                style={{ fontSize: "30px", color: "#4caf50" }}
              />
            </div>
            <h4>Course Fee: 6000 Taka</h4>
          </div>
        </div>
      </div>
      <Certificates />
      <TopLeftRadiusSection>
        <h3>Why Charterd Skills</h3>
        <p>
          Chartered Skills is a professional & educational e-learning & hiring
          platform that provides a wide range of skill-oriented courses helping
          individuals reach their professional goals. Our platform enables
          entrepreneurs to learn their industry know-how, helps job seekers
          match their skill sets with the ideal jobs & allows professionals to
          remain relevant in their industries through upskilling &
          microlearning. When you complete a course on Chartered Skills, you
          become a certified expert through our accreditations.
        </p>
        <p>
          Chartered Skills is a professional & educational e-learning & hiring
          platform that provides a wide range of skill-oriented courses helping
          individuals reach their professional goals. Our platform enables
          entrepreneurs to learn their industry know-how, helps job seekers
          match their skill sets with the ideal jobs & allows professionals to
          remain relevant in their industries through upskilling &
          microlearning. When you complete a course on Chartered Skills, you
          become a certified expert through our accreditations.
        </p>
      </TopLeftRadiusSection>
      <section className="py-5 bg-light">
        <div className="container text-center">
          <h2 className="mb-4 text-primary fw-bold">
            Meet Our Expert Instructors
          </h2>
          <div className="row justify-content-center">
            {instructors.map((instructor, index) => (
              <div key={index} className="col-md-4 col-sm-6 mb-4">
                <div className="card border-0 shadow-lg overflow-hidden instructor-card">
                  <img
                    src={instructor.image}
                    alt={instructor.name}
                    className="card-img-top"
                  />
                  <div className="card-body text-center">
                    <h4 className="text-dark fw-bold">{instructor.name}</h4>
                    <p className="text-muted">{instructor.role}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <style>
          {`
          .instructor-card {
            border-radius: 15px;
            transition: transform 0.3s ease-in-out;
          }
          .instructor-card:hover {
            transform: translateY(-10px);
          }
          .instructor-card img {
            height: 300px;
            object-fit: cover;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
          }
        `}
        </style>
      </section>
    </>
  );
}
